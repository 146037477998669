<template>
  <div class="home">
    <div class="title">
      <img :src="require('/src/assets/img/home-title.png')" />
    </div>
    <div class="panel">
      <div class="content mybutton" @click="toRouter('/odc')">
        OVERSEA DEVELOPMENT CENTER
      </div>
      <div class="content mybutton" @click="toRouter('/service')">
        OUR SERVICES
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    toRouter(url) {
      this.$router.push(url);
    },
  },
};
</script>

<style lang="less" scoped>
.home {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: url("../../assets/img/home.png") center center;
  background-size: cover;
  text-align: left;
  .title {
    width: 100%;
    height: 11.57vh;
    background: #0000008f;
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    img{
      width: 66.61vw;
      height: 5.556vh;
    }
  }
  .panel {
    position: fixed;
    top: 70%;
    display: flex;
    padding: 0px 40px;
    width: 100%;
    .content {
      flex: 1;
      height: 90px;
      opacity: 0.82;
      margin: 0px 80px;
      background: rgba(0, 0, 0, 0.85);
      border: 1.5px solid #ffffff;
      box-shadow: 3.75px 3.75px 7.5px 0px #6d7278;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 2.59vh;
      font-family: ITF Devanagari Marathi, ITF Devanagari Marathi-Bold;
      font-weight: 700;

      color: #ffffff;
      line-height: 4.54vh;
      letter-spacing: 2.06px;
    }
  }
}
</style>
